<template>
<div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <template v-if="view == 'list'">
            <div class="d-flex align-items-center flex-wrap mr-1">
                <div class="d-flex align-items-baseline flex-wrap mr-5">
                    <h5 class="text-dark font-weight-bold my-1 mr-5">Schools</h5>

                    <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 font-size-sm">
                        <li class="breadcrumb-item">
                            {{school_length}} Total
                        </li>
                    </ul>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <div class="card-toolbar card-actions">
                    <a href="Javascript:void(0)"  @click="addSchool" class="btn font-weight-bolder add-action mr-3">
                        <i class="fas fa-plus-circle icon-md"></i>Add School
                    </a>
                    <a href="Javascript:void(0)" @click="exportSchools" class="btn font-weight-bolder export-action">
                        <i class="fas fa-file-export icon-md"></i>Export CSV Schools</a>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="card-toolbar card-actions">
                <a href="Javascript:void(0)"  @click="viewList" class="btn font-weight-bolder add-action mr-3">
                    <i class="fas fa-arrow-left icon-md"></i>Back
                </a>
            </div>
        </template>
    </div>
</div>

</template>
<script>
import { defineComponent, computed, ref, onMounted, watch, inject  } from 'vue'
import { useStore, mapGetters   } from 'vuex'
import { useToast } from "primevue/usetoast";

export default defineComponent({
    props: {
        school_list_length: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const store = useStore()
        const toast = useToast()
        const constant = inject('Constant')
		const http = inject('Http')

        let school_length = ref(props.school_list_length)
        function addSchool() {
            store.commit('SchoolsModule/CREATE_SCHOOLS', 'create')
        }
        function viewList() {
            store.commit('SchoolsModule/CREATE_SCHOOLS', 'list')
        }
        
        function exportSchools() {
            window.open(`${process.env.VUE_APP_API_URL}${constant.SCHOOLS_EXPORT}?token=${localStorage.getItem("token")}`)
        }
        watch(()=>props.school_list_length, (n) => {
           school_length.value = props.school_list_length
        })
        return {
            view: computed(() => store.getters['SchoolsModule/GET_VIEW']),
            addSchool, viewList,
            school_length, exportSchools
        }
    },
})
</script>

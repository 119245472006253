<template>
<div class="d-flex flex-column-fluid">
<div class="container action-page-container">
    <div class="card card-custom">
        <div class="card-body ">
            <div class="row align-items-center h-100 pt-5 pb-5" >
                <div class="col-12">
                    <form ref="formData" @submit.prevent="save" action="post" class="form action-single-pages" >
                        <h1> {{ title }}</h1>
                        <div class="form-container">    
                            <div class="form-group">
                                <label>School Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="name" name="name" placeholder="Enter school name" required/>
                                <span class="form-text text-muted">Please enter school name</span>
                            </div>
                            <div class="form-group">
                                <label>Address</label>
                                <input type="text" class="form-control" v-model="address" name="address"  placeholder="Enter school address"/>
                                <span class="form-text text-muted">Please enter school address</span>
                            </div>
                            <div class="form-group">    
                                <label>Description</label>
                                <textarea class="form-control"  v-model="descriptions" name="descriptions" placeholder="Enter school description"></textarea>
                                <span class="form-text text-muted">Please enter school description</span>
                            </div>
                            <div id="kt_repeater_1">
                                <div class="form-group">
                                    <label>Add Class</label>
                                    <div>
                                        <div class="mb-2" v-for="(item, i) in class_list" :key="i">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i class="fas fa-school"></i>
                                                    </span>
                                                </div>
                                                <input type="text" v-model="class_list[i].name"  class="form-control" placeholder="Enter Class"/>
                                                <div class="input-group-append">
                                                    <a href="javascript:void(0);" @click="removeClass(i)" class="btn font-weight-bold btn-danger btn-icon">
                                                        <i class="la la-close"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div @click="addClass" class="btn font-weight-bold btn-warning">
                                        <i class="la la-plus"></i>Add Class
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button :disabled="create_status" type="submit" class="btn btn-primary mr-2">
                                    <span v-if="!create_status">Submit</span>
									<span v-else> <i class="fas fa-spinner fa-spinner"></i> Submitting...</span>
                                </button>
                                <button @click="cancel"  type="button" class="btn btn-secondary">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</template>
<script>
import  SchoolsCreate  from '@/composables/schools/SchoolsCreate'

import { useStore  } from 'vuex'
import { defineComponent, ref, inject, watch, toRefs,  computed, onMounted, toRef} from 'vue'
import { useToast } from "primevue/usetoast";
export default defineComponent({
    props: {
        view: {
            type: String,
            default: 'create' 
        },
        selected_school: {
            default: []
        }
    },
    setup(props) {
        const constant = inject('Constant')
		const http = inject('Http')
        const toast = useToast()
        const store = useStore()

        let class_list = ref([{name: ''}])
        let descriptions = ref('')
        let address = ref('')
        let name = ref('')
        let title = ref('Create School')
        let formData = ref('')
        let spinner = ref(false)

        const { create_status, createSchool } = SchoolsCreate(http, constant, toast)

        function addClass() {
            class_list.value.push({name: ''})
        }

        function removeClass(i) {
            class_list.value.splice(i, 1)
        }
        
        async function save() { 
            createSchool(props.selected_school.id, class_list, props.view, formData.value)
            if (props.view == 'create')
                    setVal('', '', '', [{name: ''}])
        }
        
        function setVal(desc, n, a, cl) {
                descriptions.value = desc
                name.value = n
                address.value = a
                class_list.value = cl
        }
        
        function cancel() {
            store.commit('SchoolsModule/CREATE_SCHOOLS', 'list')
        }

        onMounted(() => {
            title.value = 'Create School'
            if (props.view == 'edit') {
                title.value = `Update School Details for ${props.selected_school.name}`
                setVal(props.selected_school.descriptions, props.selected_school.name, props.selected_school.address, props.selected_school.classesname)
            }
        })
        return {
            class_list, descriptions, address, name, create_status, title,
            addClass,
            removeClass,
            save,
            cancel,
            formData
        } 
    },
    
})
</script>
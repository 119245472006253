<template>
<div>
    <SchoolsSubHeader  :school_list_length="school_list.length"></SchoolsSubHeader>
    <SchoolsCreateOrEdit v-if="view == 'create' || view == 'edit'" :view="view" :selected_school="selected_school"></SchoolsCreateOrEdit>
    <div class="d-flex flex-column-fluid" v-if="view == 'list'">
        <div class="container">
            <div class="card card-custom">
                <div class="card-body">
                   <div class="table-responsive">
					   <DataTable  class="p-datatable-lg" ref="dt"  :value="school_list"
                        dataKey="id" v-model:filters="vm.filter_list"  filterDisplay="menu"  :loading="loading" responsiveLayout="stack" breakpoint="960px"
                        :globalFilterFields="['name']"
					   	:paginator="true" :rows="10"
                        :resizableColumns="true" columnResizeMode="fit"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						:rowsPerPageOptions="[10,20,50]">
                            <template #header>
                            <div class="card-header  p-0 pt-0 border-0">
                                <h3 class="card-title  u-flex">
                                    <span class="card-label fw-bolder fs-3 mb-1 u-flex-1">Schools List</span>
                                    <div class="p-d-flex p-jc-between me-2 searchbar-width">
                                        <span class="p-input-icon-left text-blue">
                                            <i class="pi pi-search" />
                                            <InputText class="tableSearch" v-model="vm.filter_list['name'].value" placeholder="Search..." />
                                        </span>
                                    </div>
                                </h3>
                            </div>
                            </template>
							<Column field="name" header="School name" :sortable="true"></Column>
							<Column field="address" header="Address" :sortable="true"></Column>
							<Column field="descriptions" header="Descriptions" :sortable="true"></Column>
							<Column field="classesname" header="Classes" :sortable="true">
                                <template #body="slotProps">
                                        <Tag  v-for="(row, i) in slotProps.data.classesname" :key="i" :value="row.name" class="mr-2 ml-2 fs-13"></Tag>
                                </template>

                            </Column>
                            <Column field="id" header="">
                                <template #body="slotProps">
                                    <a href="Javascript:void(0)" @click="edit(slotProps)" class="btn btn-sm btn-clean btn-icon mr-2 action-edit actions-table" title="Edit details">
                                        <span class="svg-icon svg-icon-md">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                                                    <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                                                </g>
                                            </svg>
                                        </span>
                                    </a>
                                    <a href="Javascript:void(0)" @click="deletePrompt(slotProps)" class="btn btn-sm btn-clean btn-icon action-delete actions-table" title="Delete">
                                        <span class="svg-icon svg-icon-md">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"/>
                                                    <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                                                </g>
                                            </svg>
                                        </span>
                                    </a>
                                </template>
                            </Column>
                            <template #loading>
                                Loading customers data. Please wait.
                            </template>
                            <template #empty>
                                No users found.
                            </template>
						</DataTable>
					</div>
                </div>
            </div>
        </div>
    </div>
   
</div>
</template>
<script>
import  SchoolsGetList  from '@/composables/schools/SchoolsGetList'
import  SchoolsDelete  from '@/composables/schools/SchoolsDelete'

import { defineComponent, ref, watch, reactive, onMounted, inject, computed } from 'vue'
import SchoolsCreateOrEdit from './SchoolsCreateOrEdit.vue'
import SchoolsSubHeader from './SchoolsSubHeader.vue'
import { useStore  } from 'vuex'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import {FilterMatchMode} from 'primevue/api'
import InputText from 'primevue/inputtext'
import Tag from 'primevue/tag';


import { useConfirm } from "primevue/useconfirm"
import { useToast } from "primevue/usetoast";
export default defineComponent({
    components: { SchoolsSubHeader, SchoolsCreateOrEdit, Tag, DataTable, Column, InputText  },
    setup() {
        const constant = inject('Constant')
		const http = inject('Http')
        const store = useStore()
        const confirm = useConfirm();
        const toast = useToast();

        const vm = reactive({
            filter_list: { 'name': {value: null, matchMode: FilterMatchMode.CONTAINS} }
        })
        let view = ref('list')
        let selected_school = ref([])

        const { loading, school_list, getSchoolsList } = SchoolsGetList(http, constant, store)
        const { delete_status, deleteSchool } = SchoolsDelete(http, constant, toast)


        function edit(s) {
            selected_school.value = s.data
            store.commit('SchoolsModule/CREATE_SCHOOLS', 'edit')
        }
        function deletePrompt(s) {
            selected_school.value = s.data
            confirm.require({
                message: `Are you sure you want to delete ${ selected_school.value.name}?`,
                header: 'Ooops!',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    deleteNow()
                },
                reject: () => {
                }
            });
            return {deletePrompt};
        } 
        function deleteNow() {
            deleteSchool(selected_school.value.id)
        }
        view = computed(() => store.getters['SchoolsModule/GET_VIEW'])

        watch(view, (n, o) => {
            if (view.value == 'list')
                getSchoolsList()
        });
        watch(delete_status, (n, o) => {
            if (delete_status.value)
                getSchoolsList()
        });

        onMounted(() => {
            getSchoolsList()
        })
        return {
            view,
            vm,
            school_list, loading, selected_school,
            getSchoolsList,
            edit, deletePrompt
        }
    },
})
</script>

import { ref } from 'vue'
export default function SchoolsCreate(http, constant, toast) {
    const create_status = ref(false)
    async function createSchool(id, class_list, view, formData) {
        try {
            create_status.value = true

            const f = new FormData(formData);
            f.append('classes', JSON.stringify(class_list.value))

            if (view  == 'edit')  
                f.append('id', id)

            const response = await http.post(view  == 'edit' ? constant.SCHOOLS_EDIT :  constant.SCHOOLS_CREATE, f)
            create_status.value = false
            toast.add({severity:'success', summary: 'Success', detail:'School was successfully created/updated.', life: 3000});

        } catch (err) {
            create_status.value = false
        }
    }
    return {
        create_status,
        createSchool
    }
}